.basic-data {
  height: 40px;
  line-height: 40px;
  background: #f0f0f0;
  font-size: 18px;
  padding-left: 9px;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.data-list {
  padding-left: 15px;
}
.teamSelect {
  display: inline-block;
  width: 78px;
  padding-right: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}
.mutrixStyle {
  display: none;
}
/*管理员账号*/
.accountNumber {
  color: #1890ff;
  font-size: 16px;
  cursor: pointer;
}
.ant-table {
  font-size: 12px;
}
