.btn-box {
  margin-left: auto;
}
.btn-add {
  margin-bottom: 16px;
}
.batch {
  margin-right: 8px;
}
.organizationSelect {
  display: flex;
}
.teamSelect {
  display: inline-block;
  width: 78px;
  padding-right: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}
.team-box {
  margin-bottom: 28px;
}
.edit-btn {
  margin-left: 2%;
}
.coach-title {
  text-align: right;
}
.coach-title h3 {
  margin-bottom: 10px;
  text-align: left;
  background: aliceblue;
  padding-left: 12px;
  height: 35px;
  line-height: 34px;
}
.basic-data {
  height: 40px;
  line-height: 40px;
  background: #f0f0f0;
  font-size: 18px;
  padding-left: 9px;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.data-list {
  margin-top: 30px;
  padding-left: 15px;
}
/*//下一步*/
.next-btn {
  margin-top: 20%;
  margin-bottom: 60px;
  text-align: center;
}
.province-sty {
  margin-bottom: 24px;
}
.ant-table {
  font-size: 12px;
}
