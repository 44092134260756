.password-box {
  padding: 45px 57px;
  margin: 15% auto;
  width: 550px;
  height: 450px;
  background-color: #ffffff;
}
.password-box input {
  margin-bottom: 35px;
}
.password-box .ok-btn {
  padding: 0 30px;
}
.password-box button {
  margin-right: 50px;
}
.password-btn {
  background-color: green;
  color: #ffffff;
}
.password-btn:hover {
  background-color: green;
  color: #ffffff;
}
.ant-table {
  font-size: 12px;
}
