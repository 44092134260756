.att-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: rgb(24, 144, 255);
  box-shadow: 0 0 0 #ffffff;
}
button:hover,
button:focus {
  outline: 0;
  text-decoration: none;
}
.att-btn:hover,
.att-btn:focus {
  outline: 0;
  text-decoration: none;
  background: none;
}
.att-btn:active {
  outline: 0;
  border: none;
}
.ant-table {
  font-size: 12px;
}
