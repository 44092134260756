.sign:hover {
  text-decoration: underline;
}
.App-logo {
  /*animation: App-logo-spin infinite 8s linear;*/
}
.video-1 {
  position: fixed;
  top: 0;
  bottom: 0;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}
.back-img {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}
.logo-img {
  z-index: 5;
  position: fixed;
  height: 40px;
  top: 20px;
  left: 30px;
  width: auto;
}

.App-body {
  font-size: calc(10px + 2vmin);
  /*color: white;*/
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_box {
  /*position: absolute;*/
  /*top: -20%;*/
  /*right: 28%;*/
  padding: 0 20px;
  min-height: 448px;
  /*background-color: #6D7C8F;*/
  position: relative;
  width: 509px;
  height: 469px;
}

.App-back {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -40%);
  width: 100%;
  height: 100%;
}
.login-ok {
  position: absolute;
  left: 50%;
  width: 346px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  z-index: 666;
  top: 46%;
  transform: translate(-50%, -33%);
}

.App-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 8;
  transform: translate(-50%, -255%);
}
.input_frist {
  background-color: #f2f6f9;
}
.input {
  min-height: 70px;
  line-height: 70px;
  color: #7b7b7b;
  font-size: 15px;
  /*background-color: #F2F6F9;*/
}
.App-link {
  /*color: #61dafb;*/
  color: #249ddd;
}
.example-input .ant-input {
  width: 360px;
  margin: 0 8px 8px 0;
}

.login-form-button {
  background-color: #249ddd;
}
.btn-code {
  margin-left: 12px;
  border-radius: 2px;
  width: 112px;
}
.btn-code:hover {
  background-color: #249ddd;
  color: #ffffff;
  border-color: #249ddd;
}
.checkbox-organization .ant-radio-group .ant-radio-wrapper {
  display: block !important;
  height: 30px;
  line-height: 30px;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 10px;
  z-index: 1;
  font-size: 14px;
  text-align: center;
}
.ant-table {
  font-size: 12px;
}
