.btn-box {
  text-align: right;
  margin-left: auto;
}
.btn-add {
  margin-bottom: 16px;
}
.batch {
  margin-right: 8px;
}
.organizationSelect {
  display: flex;
}
.teamSelect {
  display: inline-block;
  width: 78px;
  padding-right: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}
.team-box {
  margin-bottom: 28px;
}
.edit-btn {
  margin-left: 2%;
}
.coach-title {
  text-align: right;
}
.coach-title h3 {
  margin-bottom: 10px;
  text-align: left;
  background: aliceblue;
  padding-left: 12px;
  height: 35px;
  line-height: 34px;
}
.organiza-input input {
  width: 64%;
}
.next-btn {
  margin-top: 50px;
}
.btn-box {
  text-align: right;
  margin-left: auto;
}
.btn-add {
  margin-bottom: 16px;
}
.batch {
  margin-right: 8px;
}
.organizationSelect {
  display: flex;
}
.teamSelect {
  display: inline-block;
  width: 78px;
  padding-right: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}
.teamSelect::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  /*margin-bottom: 1px;*/
}
.team-box {
  margin-bottom: 28px;
}
.edit-btn {
  margin-left: 2%;
}
.coach-title {
  text-align: right;
}
.coach-title h3 {
  margin-bottom: 10px;
  text-align: left;
  background: aliceblue;
  padding-left: 12px;
  height: 35px;
  line-height: 34px;
}
.organiza-input input {
  width: 750px;
}
.next-btn {
  margin-top: 20%;
  margin-bottom: 60px;
  text-align: center;
}
.ant-table {
  font-size: 12px;
}
