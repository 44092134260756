.btn-box {
  margin-left: auto;
}
.btn-add {
  margin-bottom: 16px;
}
.batch {
  margin-right: 8px;
}
.organizationSelect {
  display: flex;
}
.ant-upload-list-picture-card {
  width: 100%;
  height: 100%;
}
.ant-table {
  font-size: 12px;
}
