.home-wrap {
  background-color: #eeedee;
  height: calc(62vh);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
}
.map {
  width: calc(100vh);
  /*height: calc(61.5vh);*/
  background-color: #34495e;
}
.thisTable {
  height: calc(50vh);
  background-color: aquamarine;
}
/*全屏按钮*/
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}

.fullscreen {
  position: absolute;
  cursor: pointer;
  right: 6px;
  top: 21px;
  z-index: 9;
  font-size: 25px;
}
.fullscreen :hover {
  -webkit-animation: scaleDraw 3s ease-in-out infinite;
}
.ant-table {
  font-size: 12px;
}
