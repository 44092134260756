button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: rgb(24, 144, 255);
}
button:hover,
button:focus {
  outline: 0;
  text-decoration: none;
}
.nav-box {
  height: calc(100vh);
  width: 100%;
  overflow: hidden;
  background-color: #f3f4f8;
}
.nav-box .nav-left {
  /*height: calc(100vh);*/
  /*background-color: #ffffff;*/
  /*position: fixed;*/
  /*top: 73px;*/
  left: 0;
  z-index: 2;
  /*display: block;*/
  /*width:12.5%*/
}
.content-box {
  /*position: absolute;*/
  /*top: 140px;*/
  /*left: 12.5%;*/
  background-color: #f3f4f8;
  width: 91.5%;
  /*height: calc(100vh);*/
  flex: auto;
  /*margin-top: 66px;*/
  margin-top: 20px;
  height: calc(81vh);
  overflow-y: auto;
}
.content {
  margin: 0 20px 20px 20px;
  background: #ffffff;
}
.content-list {
  /*height: 90%;*/
  padding: 18px;
}
.ant-menu-dark {
  color: rgba(255, 255, 255, 0.65);
  background: #2d3e50;
}
.mutrixName .ant-form-item-control-wrapper {
  display: inline-block !important;
  width: 73%;
}
.mutrixName .ant-form-item-label {
  width: 105px;
  text-align: right;
  vertical-align: inherit;
}
.nameless .ant-form-item-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  /*margin-bottom: 1px;*/
}

/*教练的基础资料*/
.coachLess .ant-form-item-label::before {
  color: #ffffff;
}

/*组织管理*/
.organizationName .ant-form-item-control-wrapper {
  display: inline-block !important;
  width: 73%;
}
.organizationAddress {
  margin-bottom: 6px;
}
.organizationAddress .ant-form-item-control {
  line-height: 20px;
}
.organizationAddress .ant-form-item-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  /*margin-bottom: 1px;*/
}
.organizationAddress .ant-form-item-control-wrapper {
  display: inline-block !important;
  width: 75%;
}
.organizationAddress .ant-form-item-label {
  width: 82px;
  text-align: right;
  vertical-align: inherit;
}
.organizationName .ant-form-item-label {
  width: 82px;
  text-align: right;
  vertical-align: inherit;
}

.ant-modal-footer {
  text-align: center;
}

.large-form-label .ant-form-item-control-wrapper {
  display: inline-block !important;
  width: 66%;
}
.large-form-label .ant-form-item-label {
  width: 120px;
  text-align: right;
}

.huge-form-label .ant-form-item-control-wrapper {
  display: inline-block !important;
  width: 60%;
}
.huge-form-label .ant-form-item-label {
  width: 150px;
  text-align: right;
}

.double-form-label .ant-form-item-control-wrapper {
  display: inline-block !important;
  vertical-align: -17px;
  width: 60%;
}

.double-form-label .ant-input {
  text-align: center;
}
.double-form-label .ant-form-item-label {
  width: 150px;
  text-align: right;
}
.grid-dashboard {
  background: #00a0e9;
  text-align: center;
  height: 180px;
  margin: 0 20px;
  padding-top: 30px;
}

.table-row-odd {
  background: #f4f8fa;
}

.table-row-even {
  background: #fff;
}

.table-row-odd-short {
  background: #f4f8fa;
  line-height: 1;
}

.table-row-even-short {
  background: #fff;
  line-height: 1;
}

.loading-spin {
  text-align: center;
}
.checkbox-organization .ant-checkbox-group .ant-checkbox-group-item {
  width: 45%;
  margin-bottom: 10px;
}
.card-container > .ant-tabs > .ant-tabs-bar {
  text-align: center;
}
.card-container .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 0 64px;
}
.ant-upload-list-picture-card {
  width: 150px;
  height: 170px;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 150px;
  height: 150px;
}
.agreeModel {
  width: 800px !important;
}
.agreeModel .ant-modal-content {
  height: 720px !important;
  overflow: auto;
}
.checkbox-organization .ant-radio-group .ant-radio-wrapper {
  display: block !important;
  height: 30px;
  line-height: 30px;
}
/********************************上传头像*********************/
.clearfix .ant-upload-list-picture-card {
  width: 100%;
  height: 100%;
}
.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  background: #ffffff;
  border: none;
  height: 0;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 10px;
  z-index: 1;
  font-size: 14px;
  text-align: center;
}
.football-uploader .ant-upload.ant-upload-select-picture-card {
  width: 155px;
  height: 190px;
}
/*********************************组织主页******************************/
.month .ant-calendar-picker-input {
  border: none;
  width: 100px;
  opacity: 0;
  cursor: pointer;
  /*margin-right: 45px;*/
}
.month .ant-calendar-picker-icon {
  display: none;
}
.month .ant-calendar-picker-clear {
  display: none;
}
.toDay .ant-calendar-picker-input {
  border: none;
  width: 130px;
  opacity: 0;
  cursor: pointer;
}
.toDay .ant-calendar-picker-icon {
  display: none;
}
.toDay .ant-calendar-picker-clear {
  display: none;
}
.week .ant-calendar-picker-input {
  border: none;
  width: 230px;
  opacity: 0;
  cursor: pointer;
}
.week .ant-calendar-picker-icon {
  display: none;
}
.week .ant-calendar-picker-clear {
  display: none;
}

.btn-radio .ant-radio-button-wrapper-checked {
  background: #1890ff;
  color: #ffffff;
}
.anchorBL {
  display: none;
}
.mutrixStyle {
  display: none;
}
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
