.one-img {
  width: 74px;
  height: 74px;
  background: #ffffff;
  border-radius: 11px;
  padding: 3px;
  border: 2px solid #000c17;
  margin-right: 16px;
  vertical-align: -21px;
}

.one-leve {
  display: inline-block;
  width: 74px;
  height: 74px;
  font-size: 42px;
  color: #ffffff;
  background: #00c1de;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  text-align: center;
  line-height: 74px;
  margin-right: 16px;
}
.pattern {
  height: auto;
  border-top: 3px solid #ffffff;
  font-size: 20px;
  line-height: 115px;
  padding-left: 23px;
  margin-top: 16px;
}
.pattern img {
  width: 74px;
  height: 74px;
}
.levle {
  display: inline-block;
  width: 74px;
  height: 74px;
  font-size: 42px;
  color: #ffffff;
  background: #00c1de;
  border-radius: 11px;
  text-align: center;
  line-height: 74px;
  margin-left: 16px;
  vertical-align: middle;
}
.user-img img {
  width: 36px;
  height: 45px;
}
.big {
  font-size: 67px;
}
.goalkeeper {
  text-align: center;
}
.goalkeeper-box {
  display: inline-block;
  padding: 0 10px;
}
.goalkeeper-box p {
  vertical-align: -17px;
  display: inline-block;
  width: 19px;
  height: 19px;
  background: #79d03f;
}
.goalkeeper-box span {
  color: #969696;
  font-size: 22px;
}
.goalkeeper-case {
  display: inline-block;
  padding: 0 10px;
}

.goalkeeper-case p {
  vertical-align: text-top;
  display: inline-block;
  width: 19px;
  height: 19px;
}
.ant-table {
  font-size: 12px;
}
