.btn-box {
  margin-left: auto;
}
.btn-add {
  margin-bottom: 16px;
  margin-right: 8px;
}
.batch {
  margin-right: 8px;
}
.organizationSelect {
  display: flex;
}
.basic-data {
  display: flex;
  height: 40px;
  line-height: 40px;
  background: #f0f0f0;
  font-size: 18px;
  padding-left: 9px;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.basic-data p:nth-child(2) {
  margin-left: auto;
  cursor: pointer;
  padding-right: 8px;
}
.coach-data-box {
  display: flex;
  padding: 0 360px 0 8px;
}
.coach-data {
  margin-right: 30px;
}
.coach-data button {
  width: 150px;
}
.coach-data .user-img {
  width: 150px;
  height: 150px;
  margin-bottom: 8px;
}
.user-img img {
  width: 150px;
  height: 150px;
}
.coach-data-list {
  margin-left: auto;
}
.userHome {
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: -0.1%;
  text-align: center;
  height: 150px;
  width: 44px;
  border-radius: 0 20px 20px 0;
}
/*.file-uploader{*/
/*    color: #ffffff;*/
/*    width: 50px;*/
/*    height: 18px;*/
/*}*/
/*.this-file{*/
/*    position: absolute;*/
/*    padding-left: 25px;*/
/*    background: #ffffff;*/
/*}*/

/****************************************球员数据**************************/
.football-title {
  display: flex;
  height: 180px;
  background: #f0f0f0;
}
.football-img img {
  margin-top: 95px;
  margin-left: 30px;
  width: 130px;
  height: 130px;
  border-radius: 10px;
}
.ranking {
  line-height: 10px;
  margin-left: 140px;
  width: 126px;
}
.ranking h1 {
  margin-top: 40px;
}
.ranking img {
  width: 45px;
  height: 60px;
}
.ability {
  margin-left: 120px;
  width: 110px;
}
.ability h1 {
  margin-top: 52px;
  height: 40px;
}
.football-list {
  display: flex;
}
.football-left {
  width: 36%;
  padding-right: 30px;
  border-right: 20px solid #f0f0f0;
}
.football-left h1 {
  margin-top: 60px;
  margin-left: 30px;
}
.football-left h3 {
  margin-left: 30px;
  padding-left: 5px;
  border-left: 4px solid #00a0e9;
}
.football-left p {
  padding-left: 30px;
  width: 323px;
}
.radar-chart {
  width: 45%;
}
.ability-list {
  padding-left: 60px;
}
.ability-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 83%;
}
.ability-list span {
  display: inline-block;
}
.ability-list span:nth-child(1) {
  width: 88px;
  margin-bottom: 10px;
  text-align: right;
}
.ability-list span:nth-child(2) {
  width: 45px;
  height: 23px;
  line-height: 23px;
  color: #ffffff;
  font-size: 18px;
  margin-left: 40px;
  background: #6ce000;
  text-align: center;
}
/*//下一步*/
.next-btn {
  margin-top: 6%;
  margin-bottom: 15%;
  text-align: center;
}
.teamSelect {
  display: inline-block;
  width: 78px;
  padding-right: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}
.ant-table {
  font-size: 12px;
}
