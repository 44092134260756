.btn-box {
  margin-left: auto;
}
.btn-add {
  margin-bottom: 16px;
}
.batch {
  margin-right: 8px;
}
.organizationSelect {
  display: flex;
}
.teamSelect {
  display: inline-block;
  width: 78px;
  padding-right: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
}
.team-box {
  margin-bottom: 28px;
}
.edit-btn {
  margin-left: 2%;
}
/*.coach-title{*/
/*    text-align:right;*/
/*}*/
/*.coach-title h3{*/
/*    margin-bottom:10px;*/
/*    text-align:left;*/
/*    background:aliceblue;*/
/*    padding-left: 12px;*/
/*    height: 35px;*/
/*    line-height: 34px;*/
/*}*/
.basic-data {
  display: flex;
  height: 40px;
  line-height: 40px;
  background: #f0f0f0;
  font-size: 18px;
  padding-left: 9px;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.basic-data p:nth-child(2) {
  margin-left: auto;
  cursor: pointer;
  padding-right: 8px;
}
.coach-data-box {
  display: flex;
  padding: 0 360px 0 8px;
}
.coach-data {
  margin-right: 30px;
}
.coach-data button {
  width: 150px;
}
.coach-data .user-img {
  width: 150px;
  height: 150px;
  margin-bottom: 8px;
}
.user-img img {
  width: 150px;
  height: 150px;
}
.coach-data-list {
  margin-left: auto;
}
/*//下一步*/
.next-btn {
  margin-top: 6%;
  margin-bottom: 15%;
  text-align: center;
}
.add-coach {
  margin-bottom: 20px;
  padding-left: 5px;
  display: inline-block;
}
.ant-table {
  font-size: 12px;
}
