.month .ant-calendar-picker-input {
  border: none;
}
.timeDate {
  position: absolute;
  padding-left: 10px;
  padding-top: 4px;
  /*width: 100px;*/
}
.ant-table {
  font-size: 12px;
}
